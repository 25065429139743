@import '../utils/variables.scss';
@import '../utils/mixins.scss';
@import '../utils/functions.scss';

/*textbox*/
.inputWrapper,
.comboWrapper {
  // margin-top: 26px;
  @include margin(8, null, 8, null);
  position:relative;
  &.focus {
    div {
      border-color: $teal;
    }
  }

  &.optional {
    margin-bottom: calculateRem(16);
  }

  &.clearIcon {
    div {
      label {
        & + i {
          color: $gray;
          position: absolute;
          right: calculateRem(10);
          top: calculateRem(5);
          z-index: 1;
          cursor: pointer;

          &[class*='mdi-'] {
            color: $darkTeal;
            font-size: calculateRem(20);
          }

          & + i {
            color: $gray;
            position: absolute;
            right: calculateRem(30);
            top: calculateRem(3);
            z-index: 1;
            cursor: pointer;

            &[class*='mdi-'] {
              color: $darkTeal;
              font-size: calculateRem(20);
            }
          }
        }
      }

      input {
        @include padding(14, 28, 14, 24);
      }
    }
  }

  div {
    position: relative;
    @include padding(0, 0, 0, 0);

    // padding: 0px;
    label {
      top: calculateRem(-10);
      left: calculateRem(19);
      position: absolute;
      background: transparent;
      // padding: 0 5px;
      @include padding(0, 5, 0, 5);
      @include firaSansSemiBold(14);
      color: $black;
      margin-bottom: 0;
      z-index: 1;

      &:after {
        content: '';
        background: $white;
        position: absolute;
        top: calculateRem(10);
        left: 0;
        height: calculateRem(2);
        width: 100%;
        z-index: -1;
      }

      & + i {
        color: $gray;
        position: absolute;
        right: calculateRem(10);
        top: calculateRem(5);
        z-index: 1;
        cursor: pointer;

        &[class*='mdi-'] {
          color: $darkTeal;
          font-size: calculateRem(20);
        }
      }
    }

    input {
      @include border(1, 1, 1, 1, solid, $gray);
      @include borderRadius(4);
      // border: 1px solid $gray;
      // border-radius: 4px;
      background: $white;
      width: 100%;
      @include padding(13, 24, 13, 24);
      // padding: 14px 24px;
      height: calculateRem(48);
      @include sourceSansProRegular(16);
      color: $black;
      text-overflow: ellipsis;
      word-spacing: normal;

      &:disabled {
        color: $black;
        @include sourceSansProItalic(16);
        background: $white200;
      }

      &:focus {
        outline: none;
        @include border(1, 1, 1, 1, solid, $teal);
        // border: 1px solid $teal;
      }
    }

    .react-date-picker,
    .react-datetime-picker {
      width: 100%;

      .react-datetime-picker__clock {
        display: none;
      }

      .react-date-picker__wrapper,
      .react-datetime-picker__wrapper {
        width: calc(100% - 10px);
        @include border(1, 1, 1, 1, solid, $gray);
        @include borderRadius(4);
        background: $white;

        // border: 1px solid $gray;
        // border-radius: 4px;
        .react-date-picker__inputGroup,
        .react-datetime-picker__inputGroup {
          @include padding(null, null, null, 19);

          // padding-left: 19px;
          .react-date-picker__inputGroup__divider {
            float: none;
          }
        }

        .react-date-picker__button,
        .react-datetime-picker__button {
          outline: none;

          .react-date-picker__button__icon {
            stroke: $darkTeal;
          }

          g {
            stroke: $teal;
          }
        }
      }

      input {
        border: none;
        height: auto;
        @include padding(11, 5, 11, 5);

        // padding: 11px 5px;
        // margin-top: 11px;
        &:invalid {
          background: none;
        }

        &[style] {
          width: calculateRem(20) !important;
        }

        &:first-child {
          &[style] {
            width: calculateRem(20);
          }
        }

        &:last-child {
          &[style] {
            width: calculateRem(45) !important;
          }
        }
      }

      .react-date-picker__calendar,
      .react-datetime-picker__calendar {
        &[style] {
          z-index: 1111;
        }
      }

      &.react-date-picker--disabled {
        background: none;

        .react-date-picker__wrapper {
          background: $o-white200;
          color: rgba($o-gray400, 0.8);

          input {
            background: none;
          }
        }
      }

      .react-date-picker__calendar {
        background: $white200;
        @include boxShadow(0, 1, 10, $gray200);
        // @include border(1, 1, 1, 1, solid, $darkTeal);
        @include borderRadius(6, 6, 6, 6);
        border: none;

        .react-calendar {
          border: none;
          @include borderRadius(6, 6, 6, 6);

          .react-calendar__navigation {
            background: $purple;
            @include borderRadius(4, 4, 0, 0);
            margin-bottom: 0;

            button {
              font-weight: bold;
              color: $white50;

              // color: $white;
              &:hover,
              &:focus {
                background: $light-purple;
              }

              &[disabled] {
                color: $gray50;
                background: $purple;
                cursor: not-allowed;
              }
            }

            .react-calendar__navigation__prev2-button {
              @include borderRadius(4, 0, 0, 0);
            }

            .react-calendar__navigation__next-8-button {
              @include borderRadius(0, 4, 0, 0);
            }
          }

          .react-calendar__viewContainer {
            .react-calendar__month-view__weekdays {
              @include padding(8, 0, 8, 0);
              background: $purple;
              .react-calendar__month-view__weekdays__weekday {
                abbr {
                  text-decoration: none;
                  color: $white50;
                }
              }
            }

            .react-calendar__decade-view,
            .react-calendar__year-view {
              button {
                &.react-calendar__tile--hasActive {
                  background: $darker-teal;
                  color: $white50;

                  &:hover {
                    background: $darker-teal;
                  }
                }

                &:hover {
                  background: $light-teal;
                }
              }
            }

            .react-calendar__month-view {
              .react-calendar__month-view__days {
                button {
                  @include border(1, 1, 1, 1, solid, $white300);

                  &.react-calendar__tile--active {
                    background: $darker-teal;

                    &:hover {
                      background: $darker-teal;
                    }
                  }

                  &:hover {
                    background: $teal;
                  }

                  &:disabled {
                    cursor: not-allowed;
                    color: $gray50;
                  }
                }
              }
            }
          }
        }
      }
    }

    .react-datetime-picker {
      .react-datetime-picker__inputGroup {
        input:nth-child(6) {
          &[style] {
            width: calculateRem(32) !important;
          }
        }
      }
    }

    textarea {
      @include border(1, 1, 1, 1, solid, $gray);
      @include borderRadius(4, 4, 4, 4);
      // border: 0px;
      width: 100%;
      @include padding(14, 24, 14, 24);
      // margin-top: calculateRem(-10);
      // padding: 0px 24px;
      height: calculateRem(118);
      resize: none;
      @include sourceSansProRegular(16);
      color: $black;

      &:focus {
        outline: none;
      }

      .errorMsg {
        margin-top: calculateRem(-6);
      }

      &:disabled {
        background: $o-white200;
        color: rgba($o-gray400, 0.8);
      }
      &.small {
        height: calculateRem(75);
      }
    }
  }

  span {
    float: right;
    @include sourceSansProRegular(13);
    
    @include padding(4, null, 4, null);
    height: calculateRem(24);
    margin-left: calculateRem(6);
    &.textArea {
      margin-top: calculateRem(-6);
    }
  }

  .errorMsg {
    display: block;
    @include padding(4, null, 4, null);
    min-height: calculateRem(24);

    span {
      padding: 0;
    }

    &.textArea {
      margin-top: calculateRem(-6);
      top: calculateRem(-2);
      right: calculateRem(10);
    }
  }
}

//Input Error
.errorWrapper {
  &.react-select {
    & > div {
      &:first-child {
        @include border(1, 1, 1, 1, solid, $red);
      }
    }

    .errorMsg {
      // margin-top: -26px;
    }
  }

  div {
    label {
      color: $red !important;
    }

    input,
    textarea {
      @include border(1, 1, 1, 1, solid, $red, !important);
    }
  }

  .errorMsg {
    display: block;
    @include padding(4, null, 4, null);
    min-height: calculateRem(24);

    span {
      float: right;
      color: $red;
      @include sourceSansProRegular(13);
    }
  }

  .react-date-picker,
  .react-datetime-picker {
    input {
      border: none !important;
    }

    .react-date-picker__wrapper,
    .react-datetime-picker__wrapper {
      @include border(1, 1, 1, 1, solid, $red, !important);
    }
  }
}

/*Radio Button*/
.radioControl {
  display: block;
  position: relative;
  @include padding(0, 0, 0, 30);
  @include margin(20, 10, 0, 0);
  // padding-left: 30px;
  // margin-top: 20px;
  // margin-left: 0px;
  // margin-right: 10px;
  cursor: pointer;
  line-height: calculateRem(20);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  @include sourceSansProRegular(16);

  &:hover {
    input {
      & ~ .checkmark {
        background-color: #fff;
      }
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked {
      & ~ .checkmark {
        background-color: #fff;
        @include border(2, 2, 2, 2, solid, $teal);
        &:after {
          display: block;
        }
      }
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: calculateRem(20);
    width: calculateRem(20);
    @include border(2, 2, 2, 2, solid, $gray);
    // border: 2px solid $gray;
    background-color: #fff;
    border-radius: 50% !important;

    &:after {
      content: '';
      position: absolute;
      display: none;
      top: calculateRem(3);
      left: calculateRem(3);
      width: calculateRem(10);
      height: calculateRem(10);
      border-radius: 50%;
      background: $teal;
    }
  }
}

.checkControl {
  display: block;
  position: relative;
  padding-left: calculateRem(30);
  margin-bottom: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  @include sourceSansProRegular(16);
  color: #000;
  line-height: calculateRem(20);
  margin-bottom: calculateRem(28);
}

.checkControl.readOnly {
  pointer-events: none;
}

.showCursor {
  cursor: pointer;
  margin-bottom: calculateRem(2);
}

.checkControl input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkControl .checkmark,
.checkControl .indeterminate {
  position: absolute;
  top: 0;
  left: 0;
  height: calculateRem(18);
  width: calculateRem(18);
  @include borderRadius(2, 2, 2, 2);
  background-color: #fff;
  @include border(1, 1, 1, 1, solid, rgba(0, 0, 0, 0.4));
}

.checkControl .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.checkControl input:checked ~ .checkmark {
  background: $teal;
}

.checkControl input:checked ~ .checkmark:after {
  display: block;
}

.checkControl .checkmark:after {
  left: calculateRem(5);
  top: 0;
  width: calculateRem(7);
  height: calculateRem(13);
  @include border(0, 3, 3, 0, solid, $white);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkControl .indeterminate:after {
  content: '';
  position: absolute;
  display: none;
}

.checkControl input:indeterminate ~ .indeterminate:after {
  display: block;
}

.checkControl input:indeterminate ~ .indeterminate {
  background-color: $teal;
  @include border(1, 1, 1, 1, solid, $teal);
}

.checkControl .indeterminate:after {
  left: calculateRem(3);
  top: calculateRem(7);
  width: calculateRem(11);
  height: calculateRem(2);
  background: #fff;
  @include border(0, 2, 2, 0, solid, $white);
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

/*Toogle button*/
.switchWrapper {
  line-height: calculateRem(12);
  margin-bottom: calculateRem(10);
}

.switch {
  position: relative;
  display: inline-block;
  width: calculateRem(45);
  height: calculateRem(15);
  left: calculateRem(25);
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $white;
  @include border(1, 1, 1, 1, solid, $gray);
  // border: 1px solid $gray;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: calculateRem(19);
  width: calculateRem(19);
  left: calculateRem(-1);
  bottom: calculateRem(-3);
  background-color: $gray;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch input:checked + .slider {
  background-color: $lighter-teal;
}

.switch input:checked + .slider:before {
  background-color: $darkTeal;
}

.switch input:focus + .slider {
  @include boxShadow(0, 0, 1, $lighter-teal);
  // box-shadow: 0 0 1px $lighter-teal;
}

.switch input:checked + .slider:before {
  -webkit-transform: translateX(calculateRem(26));
  -ms-transform: translateX(calculateRem(26));
  transform: translateX(calculateRem(26));
}

/* Rounded sliders */
.slider.round {
  // border-radius: 34px;
  @include borderRadius(34, 34, 34, 34);
}

.slider.round:before {
  border-radius: 50%;
}

/*Buttons*/
@mixin bttn {
  box-shadow: none;
  // padding: 10px;
  @include padding(9, 10, 9, 10);
  cursor: pointer;
  min-width: calculateRem(174);
  // border-radius: 4px;
  @include borderRadius(4, 4, 4, 4);
  @include firaSansSemiBold(19);

  //border: $border;
  &.small {
    @include padding(2, 10, 2, 10);
    // padding: 2px 10px;
    height: calculateRem(32);
    @include firaSansSemiBold(19);
  }
  &.callAction {
    @include padding(2, 10, 2, 10);
    height: calculateRem(35);
    @include firaSansSemiBold(16);
    min-width: calculateRem(100);
    margin-right: calculateRem(8);
  }
  &.approve {
    @include padding(2, 8, 2, 8);
    height: calculateRem(28);
    @include firaSansSemiBold(14);
    min-width: calculateRem(60);
    // margin-right: calculateRem(8);
  }
  &.Tag {
    @include padding(2, 8, 2, 8);
    height: calculateRem(40);
    width: calculateRem(90);
    @include firaSansSemiBold(14);
    min-width: calculateRem(60);
  }
  &.ebidCamp {
    // @include padding(2, 10, 2, 10);
    width: calculateRem(160);
    @include firaSansSemiBold(16);
    line-height: calculateRem(20);
  }
  &.ebidCampNot {
    // width: calculateRem(140);
    @include firaSansSemiBold(15);
    line-height: calculateRem(20);
  }
}

.bttn-primary {
  @include bttn;
  background: $teal;
  // border: 1px solid transparent;
  @include border(1, 1, 1, 1, solid, transparent);
  color: $white;

  &:hover {
    background: #197582;
    color: $white;
  }

  &:active {
    background: $darkTeal;
    outline: none;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background: $gray;
    cursor: no-drop;
    color: $o-white200;
  }
}

.bttn-secondary {
  @include bttn;
  background: transparent;
  // border: 1px solid $teal;
  @include border(1, 1, 1, 1, solid, $teal);
  color: $darkTeal;
  text-align: center;

  &:hover {
    background: rgba(51, 149, 164, 0.24);
  }

  &:active {
    // background: $lighter-teal;
    outline: none;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background: transparent;
    cursor: no-drop;
    color: $gray;
    // border: 1px solid $gray;
    @include border(1, 1, 1, 1, solid, $gray);
  }
}

.bttn-accent {
  @include bttn;
  background: $dark-purple;
  // border: 1px solid $purple;
  @include border(1, 1, 1, 1, solid, $dark-purple);
  color: $white;

  &:hover {
    background: #6b4e9e;
  }

  &:active {
    background: $dark-purple;
    outline: none;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background: $gray;
    cursor: no-drop;
    color: $o-white200;
    // border: 1px solid $gray;
    @include border(1, 1, 1, 1, solid, $gray);
  }
}

.bttn-continue {
  float: right;
}

/*Select / Dropdown*/
.selectWrapper {
  // border: 1px solid $gray;
  margin-bottom: calculateRem(8);
  margin-top: calculateRem(8);

  & > div:first-child {
    @include border(1, 1, 1, 1, solid, $gray);
    // border-radius: 4px;
    @include borderRadius(4, 4, 4, 4);
    position: relative;
    // padding: 3px 0px;
    @include padding(3, 0, 3, 0);
    background: $white;

    label {
      top: calculateRem(-10);
      left: calculateRem(19);
      position: absolute;
      // background: $o-white200;
      // padding: 0 5px;
      @include padding(0, 5, 0, 5);
      @include firaSansSemiBold(14);
      color: $black;
      z-index: 1;

      &:after {
        content: '';
        background: $white;
        position: absolute;
        top: calculateRem(8);
        left: 0;
        height: calculateRem(4);
        width: 100%;
        z-index: -1;
      }
    }
  }

  .errorMsg {
    display: block;
    @include padding(4, null, 4, null);
    min-height: calculateRem(24);
  }

  &.small {
    & > div:first-child {
      label {
        &::after {
          background: none !important;
        }
      }
    }
    .dd-wrapper {
      padding: 0;
      .dd-list {
        top: calculateRem(28);
      }
    }
    .errorMsg {
      display: none;
    }
  }
}

/* / Dropdown*/
.selectWrapper,
.comboWrapper {
  cursor: pointer;

  .dd-wrapper {
    width: auto;
    // padding: 0px;
    margin-left: calculateRem(1);
    margin-right: calculateRem(1);
    @include padding(8, 0, 8, 0);

    .dd-header {
      display: flex;
      border: none;
      background: $white;

      span {
        font-size: calculateRem(18);
        width: calculateRem(30);
        text-align: center;
        @include padding(3, 4, 0, 0);
      }
    }

    .dd-header-title {
      flex: 1;
      @include sourceSansProRegular(16);
      // margin: 0px 10px;
      @include margin(0, 10, 0, 10);
      padding-left: calculateRem(13);
    }

    .dd-list {
      z-index: 10;
      position: absolute;
      top: calculateRem(43);
      width: calc(100% - -0.125rem);
      @include border(1, 1, 1, 1, solid, $gray);
      @include borderRadius(0, 0, 4, 4);
      background: $white;
      // padding: 10px 13px;
      @include padding(10, null, 10, null);
      margin-bottom: 0;
      margin-left: calculateRem(-2);
      max-height: calc(100vh - 50vh);
      overflow: auto;
    }

    .dd-list-item {
      @include sourceSansProRegular(16);
      @include padding(8, 8, 8, 8);
      // margin-top: calculateRem(5);
      // margin-bottom: calculateRem(5);
      line-height: calculateRem(20);
      padding-left: calculateRem(30);
      cursor: pointer;

      span {
        font-size: 1.2rem;
        color: $teal;
        margin-right: calculateRem(0);
        margin-top: calculateRem(0);
        margin-left: calculateRem(3);
        float: none;
        position: absolute;
        left: calculateRem(0);
        top: auto;
      }

      &.selected {
        background: rgba($teal, 0.2);
        // color: $black;
      }

      &:hover {
        background: rgba($teal, 0.2);
        color: $black;

        span {
          color: $teal;
        }
      }
    }
  }
}

.comboWrapper {
  div {
    label {
      top: calculateRem(-10);
    }

    i {
      top: calculateRem(7) !important;
    }

    .dd-wrapper {
      @include padding(0, null, 0, null);

      .dd-list {
        // max-height: calc(100vh - 50vh);
        // overflow: auto;
        top: calculateRem(45);
        width: 100%;
        margin-left: 0;
        @include border(1, 1, 1, 1, solid, $gray);
        @include borderRadius(0, 0, 4, 4);
      }

      .dd-list-item {
        // text-indent: -33px;
        padding-left: calculateRem(28);

        span {
          float: none;
          position: absolute;
          left: calculateRem(2);
          top: auto;
          margin-top: calculateRem(-3);
        }
      }
    }
  }
}

// React Select
.react-select {
  @include margin(8, null, 8, null);

  & > div {
    &:first-child {
      @include border(1, 1, 1, 1, solid, $gray);
      // border-radius: 4px;
      @include borderRadius(4, 4, 4, 4);
      position: relative;
      // padding: 3px 0px;
      // @include padding(4, 0, 4, 0);
      margin-bottom: calculateRem(0);
      background: $white;
      min-height: calculateRem(48);
    }
    &:focus,
    &:focus-within,
    &:hover {
      border-color: $teal;
      outline: none;
    }
  }

  label {
    top: calculateRem(-10);
    left: calculateRem(19);
    position: absolute;
    @include padding(0, 5, 0, 5);
    @include firaSansSemiBold(14);
    color: $black;
    z-index: 1;

    &:after {
      content: '';
      background: $white;
      position: absolute;
      top: calculateRem(9);
      left: 0;
      height: calculateRem(2);
      width: 100%;
      z-index: -1;
    }
  }

  span {
    float: right;
    @include sourceSansProRegular(13);
    color: $placeholdergray;
    @include padding(4, null, 4, null);
    height: calculateRem(24);
    margin-left: calculateRem(6);
  }

  &.hideErrorMsg {
    .errorMsg {
      display: none;
    }
  }

  .errorMsg {
    display: block;
    @include padding(4, null, 4, null);
    min-height: calculateRem(24);
  }

  .custom-react-select {
    input {
      border: none !important;
    }
  }

  &.disable {
    [class*='css-'][class$='-control'] {
      background: $o-white200;
      color: rgba($o-gray400, 0.8);
    }
  }

  &.small {
    & > div {
      min-height: calculateRem(28);
      min-width: calculateRem(72);
      [class*='css-'][class$='-singleValue'] {
        font-size: calculateRem(16);
        padding-left: 0;
      }
    }
    label {
      display: none;
    }
    .custom-react-select {
      [class*='css-'][class$='-control'] {
        min-height: calculateRem(28) !important;
        min-width: calculateRem(64);
        padding-top: 0;
        padding-bottom: 0;
        [class*='css'][class$='indicatorContainer'] {
          @include padding(2, 4, 2, 4);
        }
      }
      [class*='css-'][class$='-menu'] {
        top: calculateRem(21);
        [class*='css-'][class$='-option'] {
          font-size: calculateRem(16);
        }
      }
    }

    .errorMsg {
      display: none;
    }
  }
}

//File Upload
.uploadWrapper {
  position: relative;
  overflow: hidden;
  display: inline-grid;
  cursor: pointer;

  // .btn {
  // 	border: 2px solid gray;
  // 	color: gray;
  // 	background-color: white;
  // 	padding: 8px 20px;
  // 	border-radius: 8px;
  // 	font-size: 20px;
  // 	font-weight: bold;
  //   }
  &.icon {
    overflow: inherit;
    // top: calculateRem(8);
    position: absolute;
    right: calculateRem(15);
    margin-top: calculateRem(-28);
    float: right;

    i {
    }
  }

  button {
    @include firaSansSemiBold(16);
  }

  &.errorWrapper {
    button {
      border-color: $red;
      //color: $red;
    }
  }

  input[type='file'] {
    // font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;

    &:disabled {
      cursor: no-drop;
    }
  }
}

// Time picker
.timePickerWrapper {
  position: relative;
  label {
    top: calculateRem(-9);
    left: calculateRem(19);
    position: absolute;
    // background: $o-white200;
    // padding: 0 5px;
    @include padding(0, 5, 0, 5);
    @include firaSansSemiBold(14);
    color: $black;
    z-index: 1;

    &:after {
      content: '';
      background: $white;
      position: absolute;
      top: calculateRem(9);
      left: 0;
      height: calculateRem(2);
      width: 100%;
      z-index: -1;
    }
  }
  & > div {
    // @include margin(8, null, 8, null);
    display: flex;
    @include border(1, 1, 1, 1, solid, $gray);
    @include borderRadius(4, 4, 4, 4);

    .react-select {
      margin: 0;

      & > div {
        &:first-child {
          border: none !important;
          min-height: calculateRem(46);
        }
      }

      &:first-child {
        // width: calculateRem(120);
        flex: 1;

        & > div {
          &:first-child {
            border-right: none !important;
            @include borderRadius(4, 0, 0, 4);
          }
          label {
            &:after {
              background: none;
            }
          }
        }
        .custom-react-select {
          [class*='css-'][class$='-control'] {
            // [class*='css-'][class$='-ValueContainer'] {
            [class*='css-'][class$='-singleValue'] {
              padding-left: calculateRem(5);
            }
            // }
          }
        }
      }

      &:last-child {
        width: calculateRem(80);

        label {
          &:after {
            background: none;
          }
        }

        & > div {
          &:first-child {
            border-left: none !important;
            @include borderRadius(0, 4, 4, 0);
            label {
              display: none;
            }
          }
        }
      }

      .custom-react-select {
        [class*='css-'][class$='-control'] {
          // [class*='css-'][class$='-ValueContainer'] {
          [class*='css-'][class$='-singleValue'] {
            padding-left: 0;
          }
          // }
        }
      }

      .errorMsg {
        display: none;
      }
    }
  }

  span {
    float: right;
    @include sourceSansProRegular(13);
    color: $placeholdergray;
    @include padding(4, null, 4, null);
    height: calculateRem(24);
  }
}

.formItemSpace {
  margin-bottom: calculateRem(38);

  &.border {
    border-color: $gray !important;
  }
}

// new date picker
.datePickerWrapper {
  // @include margin(4, null, 16, null, !important);
  

  div {
    label {
      top: calculateRem(-10);
      left: calculateRem(19);
      position: absolute;
      background: transparent;
      // padding: 0 5px;
      @include padding(0, 5, 0, 5);
      @include firaSansSemiBold(14);
      color: $black;
      margin-bottom: 0;
      z-index: 1;

      &:after {
        content: '';
        background: $white;
        position: absolute;
        top: calculateRem(10);
        left: 0;
        height: calculateRem(2);
        width: 100%;
        z-index: -1;
      }
    }
    .DateRangePicker {
      width: 100%;
      // @include borderRadius(4, 4, 4, 4);
      .DateRangePickerInput__withBorder {
        @include border(1, 1, 1, 1, solid, $gray50);
      }
      .DateRangePickerInput {
        width: 100%;
        padding-right: 0;
        @include borderRadius(4, 4, 4, 4);
        .DateRangePicker_picker {
          top: calculateRem(46) !important;
          @include border(1, 1, 1, 1, solid, $gray50);
          // @include borderRadius(4, 4, 4, 4);
          .DayPicker {
            .DayPicker_weekHeaders {
              .DayPicker_weekHeader {
                top: calculateRem(52);
                &:last-child {
                  &[style] {
                    left: calculateRem(250) !important;
                  }
                }
                .DayPicker_weekHeader_ul {
                  .DayPicker_weekHeader_li {
                    width: calculateRem(32) !important;
                    height: calculateRem(32) !important;
                    small {
                      @include sourceSansProRegular(16);
                    }
                  }
                }
              }
            }
            .DayPicker_focusRegion {
              .DayPickerNavigation {
                .DayPickerNavigation_leftButton__horizontalDefault {
                  left: calculateRem(8);
                  @include padding(6, 6, 6, 6);
                }
                .DayPickerNavigation_rightButton__horizontalDefault {
                  right: calculateRem(105);
                  @include padding(6, 6, 6, 6);
                }
              }
              .DayPicker_transitionContainer {
                &[style] {
                  width: calculateRem(525) !important;
                  height: calculateRem(300) !important;
                }
              }
              .DayPicker_transitionContainer {
                .CalendarMonthGrid {
                  .CalendarMonthGrid_month__horizontal {
                    .CalendarMonth {
                      .CalendarMonth_caption {
                        select {
                          position: relative;
                          top: calculateRem(-3);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .DayPicker__withBorder {
            box-shadow: none;
            &[style] {
              width: calculateRem(525) !important;
            }
            .CalendarMonth_table {
              tr {
                td {
                  &.CalendarDay {
                    @include sourceSansProRegular(16);
                    padding: 0;
                    &[style] {
                      width: calculateRem(32) !important;
                      height: calculateRem(32) !important;
                      line-height: calculateRem(32);
                    }
                  }
                }
              }
            }
          }
        }

        .DateRangePickerInput_calendarIcon {
          // order: 4;
          float: right;
          padding: 0;
          position: relative;
          right: auto;
          top: calculateRem(12);
          margin-right: calculateRem(10);
          margin-left: 0;
          &:hover,
          &:focus {
            outline: none;
          }
          & > svg {
            fill: $darker-teal;
          }
        }
        .DateInput {
          // order: 2;
          width: 41%;
          @include borderRadius(4, 4, 4, 4);
          background: transparent;
          .DateInput_fang {
            display: none;
          }
          .DateInput_input {
            // padding-right: calculateRem(6);
            border: none;
            @include sourceSansProRegular(15);
            color: $o-black;
            @include padding(11, 6, 11, 10);
            outline: none;
            @include borderRadius(4, 4, 4, 4);
            background: transparent;
          }
        }
        .DateRangePickerInput_arrow {
          height: calculateRem(24);
          // border-left: 1px solid $teal;
          .DateRangePickerInput_arrow_svg {
            // display: none;
            width: calculateRem(12);
            height: calculateRem(12);
          }
        }
        .DateRangePickerInput_clearDates {
          margin: 0;
          @include padding(0, 7, 0, 6);
          // display: none;
          &:hover {
            outline: none;
            border: none;
          }
          i {
            font-size: calculateRem(20);
            color: $darker-teal;
          }
        }
      }
    }
    .SingleDatePicker {
      width: 100%;
      .SingleDatePickerInput {
        width: 100%;
        .SingleDatePicker_picker {
          z-index: 11;
          .DayPicker {
          }
        }
        .SingleDatePicker_picker__directionLeft {
          @include border(1, 1, 1, 1, solid, $gray50);
          @include borderRadius(4, 4, 4, 4);
        }
        .SingleDatePickerInput_calendarIcon {
          float: right;
          position: relative;
          right: auto;
          top: calculateRem(0);
          order: 3;
          &:hover {
            outline: none;
          }
          & > svg {
            fill: $darker-teal;
          }
        }
        .SingleDatePickerInput_clearDate {
          right: calculateRem(24);
          order: 2;
          &:hover {
            background: none;
            outline: none;
          }
          & > svg {
            fill: $darker-teal;
          }
        }
        .DateInput {
          width: calculateRem(86);
          margin-left: calculateRem(16);
          margin-right: calculateRem(10);
          flex: 1;
          order: 1;
          .DateInput_fang {
            display: none;
            .DateInput_fangShape {
              // fill: $purple;
            }
            .DateInput_fangStroke {
              stroke: $gray50;
            }
          }
          .DateInput_input {
            border: none;
            @include sourceSansProRegular(16);
            color: $o-black;
            @include padding(11, 0, 11, 0);
            outline: none;
            // flex: 1;
            // order: 1;
          }
        }
        &.SingleDatePickerInput__withBorder {
          @include borderRadius(4, 4, 4, 4);
          border-color: $gray50;
          padding-right: 0 !important;
          display: flex;
          &:focus,
          &:focus-within,
          &:hover {
            outline: none;
            border-color: $teal;
          }
        }
        .DayPicker__horizontal {
          // background: $purple;
          &.DayPicker__withBorder {
            @include borderRadius(4, 4, 4, 4);
            background: $dark-purple;
            &[style] {
              width: calculateRem(270) !important;
            }
            & > div {
              & > div {
                width: calculateRem(270) !important;
              }
            }
            .DayPickerNavigation_leftButton__horizontalDefault {
              left: calculateRem(8);
              @include padding(6, 6, 6, 6);
            }
            .DayPickerNavigation_rightButton__horizontalDefault {
              right: calculateRem(10);
              @include padding(6, 6, 6, 6);
            }
            .CalendarMonthGrid {
              background: $dark-purple;
              .CalendarMonth {
                background: $dark-purple;
              }
            }
            .DayPicker_focusRegion {
              .DayPickerNavigation {
              }
              .DayPicker_transitionContainer__horizontal {
                &[style] {
                  width: calculateRem(270) !important;
                  height: calculateRem(304) !important;
                }
              }
            }
            .CalendarMonth_table {
              background: $dark-purple;
              tr {
                td {
                  background: $white50;
                  color: $o-black;
                  @include border(1, 1, 1, 1, solid, $lighter-purple);
                  width: calculateRem(32) !important;
                  height: calculateRem(32) !important;
                  &.CalendarDay {
                    @include sourceSansProRegular(16);
                    @include padding(0, 0, 0, 0);
                    @include border(1, 1, 1, 1, solid, $lighter-purple);
                    background: $white50;
                    color: $o-black;
                    line-height: calculateRem(32);

                    &:hover {
                      background: $lighter-purple;
                      color: $o-black;
                    }
                  }
                  &.CalendarDay__selected {
                    background: $dark-purple;
                    color: $white50;
                    &:active,
                    &:hover {
                      background: $dark-purple;
                      color: $white50;
                    }
                  }
                  &.CalendarDay__defaultCursor {
                    background: $white500;
                    &:hover {
                      background: $white500;
                    }
                  }
                }
              }
            }
          }
        }
        .DayPicker_weekHeader {
          top: calculateRem(55) !important;
          .DayPicker_weekHeader_ul {
            background: $light-purple;
            li {
              width: calculateRem(32) !important;
              height: calculateRem(32) !important;
              small {
                @include sourceSansProRegular(16);
                color: $o-black;
              }
            }
          }
        }
        .CalendarMonth {
          // background: $purple;
          .CalendarMonth_caption {
            padding-top: calculateRem(8);
            margin-top: calculateRem(16);
            background: $light-purple;
            select {
              background: $light-purple;
              @include border(1, 1, 1, 1, solid, $light-purple, !important);
              position: relative;
              top: calculateRem(-4);
            }
            .CalendarMonth_table {
              background: $white50;
              // td {
              // 	&.CalendarDay {
              // 		@include sourceSansProRegular(16, !important);
              // 	}
              // }
            }
          }
        }
      }
    }
  }
  i.mdi-calendar{
    position: absolute;
    right: 2.5%;
    top: 15%;
    font-size: 22px;
  }
  span {
    float: right;
    @include sourceSansProRegular(13);
    @include padding(4, null, 4, null);
    height: calculateRem(24);
    margin-left: calculateRem(6);
    // margin-bottom: calculateRem(10);
  }
  &.errorWrapper {
    .DateRangePicker {
      .DateRangePickerInput {
        .DateInput {
          input {
            border: none !important;
          }
        }
      }
    }
  }

  .DayPickerNavigation_button__default {
    border: none !important;
    background: none;
    outline: none;
    &:hover,
    &:focus,
    &:active {
      border: none !important;
      background: none;
    }
    .DayPickerNavigation_svg__horizontal {
      fill: $o-black;
    }
  }
}

.broadcastErrorMsg {
  display: block;
  @include padding(4, null, 4, null);
  min-height: calculateRem(24);
  margin-top: calculateRem(-18);

  text-align: right;
  color: $red;
  @include sourceSansProRegular(13);
}

.hideErr {
  margin-bottom: calculateRem(24);
  .errorMsg {
    display: none;
  }
}
.dateBoxAlignment{
  margin-top:0;
  margin-bottom:0;
}
